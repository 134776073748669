/*
 *@Author: 段彩翔
 *@Date: 2023-02-13 18:38:55
 *@Description: 签字弹窗
*/
<template>
	<!-- sign -->
	<ElementDialog Title="eSign" :Visible.sync="Dialog.visible" class="my-dialog-title">
		<div slot="content" class="displayFlex flexColumn flexAlignCenter flexJCCenter">
			<vueQr logoSRC="" :text="kycPDFLink" :size="180"></vueQr>
			<p class="mt10px mb10px">Scan the QR code to proceed with signing.</p>
			<!-- 因为Windows电脑还有问题，所以暂时隐藏此功能 -->
			<!-- <el-button @click="esign('copy')" style="margin-bottom: 10px;">Copy KYC PDF Link</el-button>
			<el-button @click="esign('jump')">KYC PDF Link,Click To e-Sign</el-button> -->
		</div>
	</ElementDialog>
</template>

<script>
import ElementDialog from '@/components/ElementDialog/index.vue'
import vueQr from 'vue-qr'

export default {
	props: {
		Dialog: {
			type: Object,
			default: {
				visible: false,
				status: "Add"
			}
		},
		kycPDFLink: {
			type: String,
			default: ''
		}
	},
	components: { ElementDialog, vueQr },
	data () {
		return {
		};
	},
	computed: {},
	watch: {},
	methods: {
		esign (val) {
			if (val === 'copy') {
				let url = this.kycPDFLink
				//新建一个文本框
				let oInput = document.createElement('input')
				//赋值给文本框
				oInput.value = url;
				document.body.appendChild(oInput)
				// 选择对象;
				oInput.select();
				// 执行浏览器复制命令
				document.execCommand("Copy")
				//复制完成删除掉输入框
				oInput.remove()
				this.$message.success('Copy link successfully')
			} else {
				window.open(this.kycPDFLink, '_blank')
			}
		},
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {
	},
}
</script>